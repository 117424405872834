// TODO - PÁGINA INICIAL
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Produtos'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Usuarios'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Clientes'

import { EMDESENVOLVIMENTO } from "../ImportBackend/Config/emDesenvolvimento"

// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Equipamentos'
export const LOGARBACKENDAPI: boolean = EMDESENVOLVIMENTO && false
export const LOGARBACKENDAPIRESULTADO: boolean = EMDESENVOLVIMENTO && false
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/ResetSenha'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/AtualizarCadastroProdutor'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Usuarios'
export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/Pedidos'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/'
// export const PAGINAINICIALEMDESENVOLVIMENTO: string = '/TesteBox'

export const DESATIVAR_TELA_CADASTRO_EM_DESENVOLVIMENTO: boolean = false