export const RSPEDIDO = {
  "idPessoaAutorizadaComprar": 2937,
  "idUnidadeFaturamento": 4693,
  "idCliente": 894,
  "idCondicaoPagamento": 1,
  "idMeioPagamento": 3,
  "idNaturezaOperacao": 34,
  "observacao": "",
  "perDesconto": 0.49,
  "vrDesconto": 10,
  "perAcrescimo": 0,
  "vrAcrescimo": 0,
  "vrBruto": 2038.5,
  "vrLiquido": 2028.5,
  "pagamentos": [],
  "idPedidoERP": 0,
  "idEmpresa": 0,
  "idRepresentante": 3,
  "codigoClienteERP": 0,
  "nomeFantasiaERP": "",
  "nomeClienteERP": "",
  "logradouroERP": "",
  "cidadeERP": "",
  "destacarDesconto": true,

  "pedidoItens": [
    {
      "idPedidoItem": 0,
      "idPedido": 0,
      "idEmpresa": 17,
      "idCentroEstoque": 8,
      "descricaoCentroEstoque": "INSUMOS ARAUJOS",
      "descricaoProduto": "FARELO SOJA 46% CARGILL 50KG PRODUCAO MINEIRA",
      "idProduto": 3268,
      "vrUnitario": 135.9,
      "saldoEstoque": 606,
      "permitirEstoqueNegativo": true,
      "vrTotal": 1359,
      "vrLiquido": 1359,
      "perComissao": 0.5,
      "tipoDescontoAcrescimoUnitario": true,
      "perDescontoUnitario": 0,
      "vrDescontoUnitario": 0,
      "perAcrescimoUnitario": 0,
      "vrAcrescimoUnitario": 0,
      "perDesconto": 0,
      "vrDesconto": 0,
      "perAcrescimo": 0,
      "vrAcrescimo": 0,
      "quantidade": 10,
      "desconto": 0,
      "vrSugerido": 135.9,
      "vrMinimo": 120.05,
      "vrMaximo": 145607.15,
      "idUnidadeMedida": 8
    },
    {
      "idPedidoItem": 0,
      "idPedido": 0,
      "idEmpresa": 13,
      "idCentroEstoque": 14,
      "descricaoCentroEstoque": "INSUMOS ESTRELA DO INDAIA",
      "descricaoProduto": "FARELO SOJA 46% CARGILL 50KG PRODUCAO MINEIRA",
      "idProduto": 3268,
      "vrUnitario": 135.9,
      "saldoEstoque": 446,
      "permitirEstoqueNegativo": true,
      "vrTotal": 679.5,
      "vrLiquido": 669.5,
      "perComissao": 0.5,
      "tipoDescontoAcrescimoUnitario": true,
      "perDescontoUnitario": 1.47,
      "vrDescontoUnitario": 2,
      "perAcrescimoUnitario": 0,
      "vrAcrescimoUnitario": 0,
      "perDesconto": 1.47,
      "vrDesconto": 10,
      "perAcrescimo": 0,
      "vrAcrescimo": 0,
      "quantidade": 5,
      "desconto": 0,
      "vrSugerido": 135.9,
      "vrMinimo": 120.05,
      "vrMaximo": 145607.15,
      "idUnidadeMedida": 8
    }
  ]
}

export const CONDICOES_PAGAMENTO = [
  {
    "ID_TIPO_PAGAMENTO": 1,
    "ID_CONDICAO_PAGAMENTO": 1,
    "ID_MEIO_PAGAMENTO": 3,
    "DESCRICAO": "A VISTA",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 2,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESCONTO EM FOLHA",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 3,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 4,
    "ID_CONDICAO_PAGAMENTO": 4,
    "ID_MEIO_PAGAMENTO": 22,
    "DESCRICAO": "CARTAO DEBITO",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 5,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 6,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 1 PARCELA",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 7,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 7 PARCELAS",
    "NUMEROPARCELAS": 7
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 8,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 5 PARCELAS",
    "NUMEROPARCELAS": 5
  },
  {
    "ID_TIPO_PAGAMENTO": 12,
    "ID_CONDICAO_PAGAMENTO": 9,
    "ID_MEIO_PAGAMENTO": 24,
    "DESCRICAO": "SEM PAGAMENTO",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 23,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 23 PARCELAS",
    "NUMEROPARCELAS": 23
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 24,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 24 PARCELAS",
    "NUMEROPARCELAS": 24
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 25,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 6 PARCELAS",
    "NUMEROPARCELAS": 6
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 26,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 26 PARCELAS",
    "NUMEROPARCELAS": 26
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 28,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 11 PARCELAS",
    "NUMEROPARCELAS": 11
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 29,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 12 PARCELAS",
    "NUMEROPARCELAS": 12
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 30,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 18 PARCELAS",
    "NUMEROPARCELAS": 18
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 31,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 4 PARCELAS",
    "NUMEROPARCELAS": 4
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 32,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 30 PARCELAS",
    "NUMEROPARCELAS": 30
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 33,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 36 PARCELAS",
    "NUMEROPARCELAS": 36
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 34,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 48 PARCELAS",
    "NUMEROPARCELAS": 48
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 35,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 60 PARCELAS",
    "NUMEROPARCELAS": 60
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 36,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 2 PARCELAS",
    "NUMEROPARCELAS": 2
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 37,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 3 PARCELAS",
    "NUMEROPARCELAS": 3
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 39,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 8 PARCELAS",
    "NUMEROPARCELAS": 8
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 40,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 10 PARCELAS",
    "NUMEROPARCELAS": 10
  },
  {
    "ID_TIPO_PAGAMENTO": 4,
    "ID_CONDICAO_PAGAMENTO": 42,
    "ID_MEIO_PAGAMENTO": 22,
    "DESCRICAO": "TICKET ALIMENTACAO COOPERBOM",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 43,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 2 PARCELAS",
    "NUMEROPARCELAS": 2
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 44,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 6 PARCELAS",
    "NUMEROPARCELAS": 6
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 46,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 10 PARCELAS",
    "NUMEROPARCELAS": 10
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 47,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 18 PARCELAS",
    "NUMEROPARCELAS": 18
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 48,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 24 PARCELAS",
    "NUMEROPARCELAS": 24
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 49,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 30 PARCELAS",
    "NUMEROPARCELAS": 30
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 50,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 36 PARCELAS",
    "NUMEROPARCELAS": 36
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 51,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 3 PARCELAS",
    "NUMEROPARCELAS": 3
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 52,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 4 PARCELAS",
    "NUMEROPARCELAS": 4
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 53,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 5 PARCELAS",
    "NUMEROPARCELAS": 5
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 54,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 30 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 4,
    "ID_CONDICAO_PAGAMENTO": 55,
    "ID_MEIO_PAGAMENTO": 23,
    "DESCRICAO": "CARTAO DEBITO UNICAMAX",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 56,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 57,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 2 PARCELAS",
    "NUMEROPARCELAS": 2
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 58,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 3 PARCELAS",
    "NUMEROPARCELAS": 3
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 59,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 4 PARCELAS",
    "NUMEROPARCELAS": 4
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 60,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 5 PARCELAS",
    "NUMEROPARCELAS": 5
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 61,
    "ID_MEIO_PAGAMENTO": 25,
    "DESCRICAO": "APURACAO MAVERO",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 62,
    "ID_MEIO_PAGAMENTO": 26,
    "DESCRICAO": "APURACAO CCPR",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 63,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 2 PARCELAS",
    "NUMEROPARCELAS": 2
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 64,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 3 PARCELAS",
    "NUMEROPARCELAS": 3
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 65,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 4 PARCELAS",
    "NUMEROPARCELAS": 4
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 66,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 5 PARCELAS",
    "NUMEROPARCELAS": 5
  },
  {
    "ID_TIPO_PAGAMENTO": 12,
    "ID_CONDICAO_PAGAMENTO": 67,
    "ID_MEIO_PAGAMENTO": 28,
    "DESCRICAO": "DEVOLUCAO ANTERIOR FORNECEDOR",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 68,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 7 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 9,
    "ID_CONDICAO_PAGAMENTO": 69,
    "ID_MEIO_PAGAMENTO": 30,
    "DESCRICAO": "CARTAO COMBUSTIVEL",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 12,
    "ID_CONDICAO_PAGAMENTO": 70,
    "ID_MEIO_PAGAMENTO": 29,
    "DESCRICAO": "DEVOLUCAO ANTERIOR CLIENTE",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 71,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 24 PARCELAS SEM ENTRADA",
    "NUMEROPARCELAS": 24
  },
  {
    "ID_TIPO_PAGAMENTO": 12,
    "ID_CONDICAO_PAGAMENTO": 72,
    "ID_MEIO_PAGAMENTO": 24,
    "DESCRICAO": "DEVOLUCAO CLIENTE",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 12,
    "ID_CONDICAO_PAGAMENTO": 73,
    "ID_MEIO_PAGAMENTO": 34,
    "DESCRICAO": "DEVOLUCAO FORNECEDOR",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 74,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 12 PARCELAS SEM ENTRADA",
    "NUMEROPARCELAS": 0
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 76,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE PERSONALIZADO",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 77,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 35 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 78,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "28 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 4,
    "ID_CONDICAO_PAGAMENTO": 79,
    "ID_MEIO_PAGAMENTO": 23,
    "DESCRICAO": "TICKET ALIMENTACAO COOPERBOM UNICAMAX",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 80,
    "ID_MEIO_PAGAMENTO": 26,
    "DESCRICAO": "APURACAO CCPR - 10 PARCELAS",
    "NUMEROPARCELAS": 10
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 81,
    "ID_MEIO_PAGAMENTO": 26,
    "DESCRICAO": "APURACAO CCPR - 24 PARCELAS",
    "NUMEROPARCELAS": 24
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 84,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 21 PARCELAS",
    "NUMEROPARCELAS": 21
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 86,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 6 PARCELAS",
    "NUMEROPARCELAS": 6
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 87,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "CONDICIONAL - 3 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 88,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 7 PARCELAS",
    "NUMEROPARCELAS": 7
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 89,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 8 PARCELAS",
    "NUMEROPARCELAS": 8
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 90,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 45 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 91,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 50 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 92,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 9 PARCELAS",
    "NUMEROPARCELAS": 9
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 93,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 9 PARCELAS",
    "NUMEROPARCELAS": 9
  },
  {
    "ID_TIPO_PAGAMENTO": 4,
    "ID_CONDICAO_PAGAMENTO": 94,
    "ID_MEIO_PAGAMENTO": 45,
    "DESCRICAO": "CARTAO SOCIAL CARD",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 5,
    "ID_CONDICAO_PAGAMENTO": 95,
    "ID_MEIO_PAGAMENTO": 7,
    "DESCRICAO": "DESC.FOLHA - 21 PARCELAS",
    "NUMEROPARCELAS": 21
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 96,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 7 PARCELAS",
    "NUMEROPARCELAS": 7
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 97,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 8 PARCELAS",
    "NUMEROPARCELAS": 8
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 98,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 9 PARCELAS",
    "NUMEROPARCELAS": 9
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 99,
    "ID_MEIO_PAGAMENTO": 21,
    "DESCRICAO": "CARTAO CREDITO 10 PARCELAS",
    "NUMEROPARCELAS": 10
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 100,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 6 PARCELAS",
    "NUMEROPARCELAS": 6
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 101,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 7 PARCELAS",
    "NUMEROPARCELAS": 7
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 102,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 8 PARCELAS",
    "NUMEROPARCELAS": 8
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 103,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 9 PARCELAS",
    "NUMEROPARCELAS": 9
  },
  {
    "ID_TIPO_PAGAMENTO": 3,
    "ID_CONDICAO_PAGAMENTO": 104,
    "ID_MEIO_PAGAMENTO": 27,
    "DESCRICAO": "CARTAO CREDITO UNICAMAX 10 PARCELAS",
    "NUMEROPARCELAS": 10
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 105,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 60 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 106,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 65 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 107,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO 55 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 108,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE 45 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 109,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE 30 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 110,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE 50 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 111,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE 60 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 2,
    "ID_CONDICAO_PAGAMENTO": 112,
    "ID_MEIO_PAGAMENTO": 1,
    "DESCRICAO": "CHEQUE 7 DIAS",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 15,
    "ID_CONDICAO_PAGAMENTO": 113,
    "ID_MEIO_PAGAMENTO": 47,
    "DESCRICAO": "PIX UNICAMAX",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 15,
    "ID_CONDICAO_PAGAMENTO": 114,
    "ID_MEIO_PAGAMENTO": 48,
    "DESCRICAO": "PIX TEF",
    "NUMEROPARCELAS": 2
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 115,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 12 PARCELAS",
    "NUMEROPARCELAS": 12
  },
  {
    "ID_TIPO_PAGAMENTO": 15,
    "ID_CONDICAO_PAGAMENTO": 116,
    "ID_MEIO_PAGAMENTO": 49,
    "DESCRICAO": "PIX TEF - ITAU",
    "NUMEROPARCELAS": 2
  },
  {
    "ID_TIPO_PAGAMENTO": 15,
    "ID_CONDICAO_PAGAMENTO": 117,
    "ID_MEIO_PAGAMENTO": 50,
    "DESCRICAO": "PIX UNICAMAX - ITAU",
    "NUMEROPARCELAS": 1
  },
  {
    "ID_TIPO_PAGAMENTO": 11,
    "ID_CONDICAO_PAGAMENTO": 118,
    "ID_MEIO_PAGAMENTO": 5,
    "DESCRICAO": "BOLETO - 11 PARCELAS",
    "NUMEROPARCELAS": 11
  }
]

